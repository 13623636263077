body {
  background-color: rgb(234, 234, 234) !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    -135deg,
    rgb(255, 0, 0) 0%,
    rgb(115, 61, 61) 50%,
    rgb(60, 43, 43) 89%
  ) !important;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-color: rgb(234, 234, 234) !important;
  /* background-image: linear-gradient(
    135deg,
    rgb(214, 219, 178) 0%,
    rgb(115, 61, 61) 69%,
    rgb(60, 43, 43) 89%
  ) !important; */
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/
